import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {getHelper, postHelper} from "../../general/apiHelper";
import {BASE_URL} from "../../general/constants";
import {setLocalStorageWithTimer} from "../../general/util";

const initialState = {
	location: false,
	status: 'idle',
	
}

const protectedRouteSlice = createSlice({
	name: 'protectedRoute',
	initialState,
	reducers: {
		setLocation(state, action) {
			state.location = action.payload;
		},
		clearLocation(state) {
			state.location = null;
		},
}});

export const { setLocation, clearLocation } = protectedRouteSlice.actions;

export default protectedRouteSlice.reducer;
