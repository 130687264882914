import React, {Suspense, lazy} from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";

import Loader from "./components/common/Loader";

import ProtectedRoute from "./components/common/ProtectedRoute";
import {useSelector} from "react-redux";

const NoPage = lazy(() => import('./pages/NoPage'));
const HomePage = lazy(() => import('./pages/HomePage'));
const ScreenPage = lazy(() => import('./pages/ScreenPage'));
const MyBookingsPage = lazy(() => import('./pages/MyBookingsPage'));
const TwoFAPage = lazy(() => import('./pages/TwoFAPage'));
const SignUpPage = lazy(() => import('./pages/SignUpPage'));
const OutletPage = lazy(() => import('./pages/OutletPage'));
const ReviewPage = lazy(() => import('./pages/ReviewPage'));
const IFramePage = lazy(() => import('./pages/IFramePage'));
const MySecretCodePage = lazy(() => import('./pages/MySecretCodePage'));
const CommentsPage = lazy(() => import('./pages/CommentsPage'));

function App() {
	const status = useSelector((state) => state.user.status);
	
	const isLoading = [status].includes("loading");

	if (isLoading) return <Loader />;
	return (<Router>
		<Suspense fallback={<Loader/>}>
			<Routes>
				<Route path="*" element={<NoPage/>}/>
				
				<Route exact path="/:accommodation" element={<HomePage/>}/>
				<Route exact path="/:accommodation/screen/:screenId" element={<ScreenPage/>}/>
				
				<Route exact path="/:accommodation/outlet/:outletId" element={<OutletPage/>}/>
				
				<Route exact path="/:accommodation/review" element={
					<ProtectedRoute element={ReviewPage}/>
				}/>
				
				<Route exact path="/:accommodation/bookings" element={
					<ProtectedRoute element={MyBookingsPage}/>
				}/>
				
				<Route exact path="/:accommodation/auth/2fa" element={<TwoFAPage/>}/>
				<Route exact path="/:accommodation/auth/signup" element={<SignUpPage/>}/>
				
				<Route exact path="/:accommodation/iframe/:widgetId" element={<IFramePage/>}/>
				
				<Route exact path="/:accommodation/secretCode" element={<MySecretCodePage/>}/>
				
				<Route exact path="/:accommodation/comments" element={<CommentsPage/>}/>
			</Routes>
		</Suspense>
	</Router>);
}

export default App;
